import Cookie from 'js-cookie';

import { ResponseError } from 'utils/errors';

/**
 * A standard JSON fetch utility for use with `AppQueryClient`'s query functions
 * and mutation functions. This handles:
 * - setting a set of base headers and other options that should be included on
 * requests to our API
 * - `JSON.stringify`-ing the request body
 * - checking to see if the request was successful and throwing a `ResponseError`
 * if not
 */
export async function jsonFetch<TResponseBody = unknown>(
  url: string,
  options: Omit<RequestInit, 'body'> & { body?: unknown; isInternalApi?: boolean } = {},
): Promise<TResponseBody> {
  const {
    body: bodyOption,
    headers: headersOption,
    isInternalApi = true,
    ...restOptions
  } = options;

  const requestOptions: RequestInit = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headersOption,
    },
    ...restOptions,
  };
  if (bodyOption) {
    requestOptions.body = JSON.stringify(bodyOption);
  }
  if (isInternalApi) {
    const el8Headers = {
      'X-CSRFToken': Cookie.get('csrftoken') || '',
      'X-EL8-PAGE-SESSION': el8Globals.PAGE_SESSION_ID,
    };
    requestOptions.headers = { ...requestOptions.headers, ...el8Headers };
  }

  // Ensure Content-Type is not set for FormData. The browser will automatically
  // set the correct Content-Type header, "multipart/form-data", including
  // the necessary boundary.
  if (bodyOption instanceof FormData) {
    delete (requestOptions.headers as Record<string, string>)['Content-Type'];
    requestOptions.body = bodyOption;
  }

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    // ResponseError has different expectations regarding the response
    // object compared to what 'fetch' returns. Making sure we meet the
    // expectations. We need to extract both (body and text) but can only
    // call one. Calling text first and then trying to parse JSON if possible.
    const responseText = await response.text();
    let responseBody: unknown;
    try {
      responseBody = JSON.parse(responseText);
    } catch {
      /* ignore if we can't parse the json */
    }

    throw new ResponseError(
      {
        status: response.status,
        body: responseBody,
        text: responseText,
      },
      'Response status not OK',
    );
  }

  // Making sure the content is present before attempting to read it.
  // NOTE: FastApi will return Content-Type: application/json for
  // 204 No Content responses with Content-Length: 0.
  const contentType = response.headers.get('Content-Type');
  const contentLength = response.headers.get('Content-Length');
  if (!contentType || contentLength === '0' || response.status === 204) {
    return null as unknown as TResponseBody;
  }

  if (contentType.startsWith('application/json')) {
    return response.json();
  }

  return response.text() as unknown as TResponseBody;
}
