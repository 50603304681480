// AUTOGENERATED BY 'featureflags/ff.js' DO NOT UPDATE THIS FILE DIRECTLY.
// INSTEAD, UPDATE 'featureflags/features.yaml' AND RUN 'node featureflags/ff.js gen'

/**
 * All active Elation feature flags
 */
enum Feature {
  /** If enabled, note transcription AI will create draft prescription orders for you based on the transcript provided. */
  AIDraftOrderCreate = "AIDraftOrderCreate",
  /** Enables visit note sign off via API (and other documents soon) */
  APIDocumentSigning = "APIDocumentSigning",
  /** Enable the User APIv2 endpoint for applications associated with this practice */
  APIv2UserEndpoint = "APIv2UserEndpoint",
  /** Show annual wellness visit dashboard in the population page */
  AWVDashboard = "AWVDashboard",
  /** (In Development - Do Not Enable) Enables admin-level users to export a CSV of the records shown in the user activity log */
  ActivityLogExport = "ActivityLogExport",
  /** Hide specific practice settings for non-admin users */
  AdminManagedSettings = "AdminManagedSettings",
  /** Adds support for saving insurance updates even if carrier, plan, group ID and Member ID are blank */
  AllowEmptyPatientIns = "AllowEmptyPatientIns",
  /** Show the Pt's PCP in the Clincial Profile even for solo practices */
  AlwaysShowPCPInCP = "AlwaysShowPCPInCP",
  /** Adds validation to prevent appointments from being created with ‘No location specified’ as the location */
  ApptMustHaveLocation = "ApptMustHaveLocation",
  /** Turn on to show additional visible flag for appointment slots, exposed for XO practices only */
  ApptSlotVisibleFlag = "ApptSlotVisibleFlag",
  /** Using FHIR API to schedule appointments with locations */
  ApptsOnFhir = "ApptsOnFhir",
  /** Determines whether a practice should use the Athena CCDA post processors */
  AthenaCcdaImports = "AthenaCcdaImports",
  /** Determines the Athena CCDA post processor imports lab results */
  AthenaCcdaResults = "AthenaCcdaResults",
  /** Auto-Import Billing */
  AutoImportBilling = "AutoImportBilling",
  /** Enables enhanced provider availability associated with the SchedulingTimeZone flag (availability by appointment type and location) to be visible on the Booking Site; also supports filtering by location and appointment type on the booking site. */
  AvailabilityOnBkSite = "AvailabilityOnBkSite",
  /** For supported entity types, use the experimental message batching */
  BatchWebsocketMsgs = "BatchWebsocketMsgs",
  /** Bidirectional labs */
  BiDiLabs = "BiDiLabs",
  /** Show outstanding orders in outstanding items section of patient chart, must have BidiLabs feature flag on. */
  BidiLabsOutItems = "BidiLabsOutItems",
  /** (Beta) Enables selection and sync of non-billable ICD codes for assessments and billing */
  BillDxAllowInvalid = "BillDxAllowInvalid",
  /** Enable practice settings for billing DX import coding automation configuration */
  BillDxImportConfig = "BillDxImportConfig",
  /** Enables the new billing DX Navigator UI for IMO/ICD/Problem search. */
  BillDxNavigator = "BillDxNavigator",
  /** Enables the Elation coding feedback side panel on Bill 2.0 */
  BillRulesElation = "BillRulesElation",
  /** Enables the NCCI coding feedback side panel on Bill 2.0 */
  BillRulesNCCI = "BillRulesNCCI",
  /** Enables the new Billing Home page. */
  BillingHomeEnabled = "BillingHomeEnabled",
  /** Allows patients to book appointments with an email or a phone number if enabled. If not enabled, only email will be used to match; if no match is found, a new chart will be created. */
  BookViaEmailOrPhone = "BookViaEmailOrPhone",
  /** Allow practice admins to choose which sections to show in CCDA import dialog */
  CCDAPreferences = "CCDAPreferences",
  /** DO NOT USE. Internal flag to enable features for CEHRT 2022 requirements. */
  CEHRT2022 = "CEHRT2022",
  /** New CQMs (some derived from Health Maint) to meet CPC+ requirements */
  CQMsForCPCPlus = "CQMsForCPCPlus",
  /** Enables caching of the draft notes queue */
  CachedDraftNotes = "CachedDraftNotes",
  /** Enables the Care Dashboard dialog to surface per-problem interventions, goals, and related data (beta) */
  CareDashboard = "CareDashboard",
  /** Use the new CareGaps V2 service */
  CareGapsV2 = "CareGapsV2",
  /** Enable ability to grant and revoke chart access from user account profiles */
  ChartAccessEditable = "ChartAccessEditable",
  /** Enables the filter by visit note type dropdown at the top of the patient chart feed */
  ChronoFilterByVNType = "ChronoFilterByVNType",
  /** Enables the filter by document type dropdown at the top of the patient chart feed */
  ChronoFilterDocType = "ChronoFilterDocType",
  /** Enables the new quick-actions bar at the top of the patient chart feed */
  ChronoQuickActions = "ChronoQuickActions",
  /** Enables support for co-signing visit notes. */
  CoSignature = "CoSignature",
  /** Enables access to the collectly patient payments page via an iframe */
  CollectlyIframe = "CollectlyIframe",
  /** Tie users together and allow switching between practices using dropdown */
  ContextSwitcher = "ContextSwitcher",
  /** Surfaces a link to the customer-facing case portal where customers can view and manage their support cases */
  CustomerCasePortal = "CustomerCasePortal",
  /** Enables Debug Level logging with datadog browser logging. Should be enabled by user and disabled after debug information is obtained. */
  DebugDDLogging = "DebugDDLogging",
  /** Default the checkbox off for the aggressive-chopped-fax stuff */
  DefaultFaxAttach = "DefaultFaxAttach",
  /** Flag for allowing practice staff to send delegate referrals via Direct Message */
  DelegateReferralDMs = "DelegateReferralDMs",
  /** Disable automated creation of visit notes from appointments */
  DisableCalendarVNA = "DisableCalendarVNA",
  /** Provide a way to add and edit doctags for letters. */
  DocTagsForLetters = "DocTagsForLetters",
  /** Enables Non-admin Staff only being able to delete Documents which they themselves have created AND have not yet been signed-off */
  DocumentDeletionAuth = "DocumentDeletionAuth",
  /** Enables the ability to add Dx codes to individual lab tests in an order */
  DxPerLabTest = "DxPerLabTest",
  /** Enables practice settings for splitting IMO concepts that contain multiple ICD codes */
  DxSplitConfig = "DxSplitConfig",
  /** Prescription electronic prior authorization functionality. */
  EPA = "EPA",
  /** eRx */
  ERx = "ERx",
  /** Enables a signature question to be added to patient forms. */
  ESignatureOnPtForms = "ESignatureOnPtForms",
  /** Determines whether a practice should use the ECW CCDA post processors */
  EcwCcdaImports = "EcwCcdaImports",
  /** Enables integration with Elation Billing */
  ElationBilling = "ElationBilling",
  /** Enables integration with Elation Billing canonical insurance list (beta) */
  ElationBillingIns = "ElationBillingIns",
  /** Enables the ability to view the Elation Billing dashboard and settings */
  ElationBillingNav = "ElationBillingNav",
  /** (Beta) Enables integration with Elation Billing canonical payments */
  ElationBillingPay = "ElationBillingPay",
  /** Permission to login via the Elation Go mobile app. */
  ElationGo = "ElationGo",
  /** Practice Level Feature Flag for enabling Provider Zoom Integration */
  ElationZoom = "ElationZoom",
  /** Enables customers currently using Elation Zoom to opt-out before December 2024 if they don't want to be charged for the functionality */
  ElationZoomOptOut = "ElationZoomOptOut",
  /** Email Import */
  EmailImport = "EmailImport",
  /** Propagate patient demographic changes to other practices in the enterprise */
  EntDemogPropagation = "EntDemogPropagation",
  /** Limit enterprise search results to those patients whose employer is in the practice */
  EntSearchByEmployer = "EntSearchByEmployer",
  /** Allows the practice to use the new Fax Delegate option in the fax inbox so they can delegate sign-off of documents to another user directly from the fax inbox */
  FaxDelegateModal = "FaxDelegateModal",
  /** Use cached value to return fax inbox count. */
  FaxInboxCountCache = "FaxInboxCountCache",
  /** Allows practices to set a default sort order for the Fax inbox (newest first vs oldest first) */
  FaxInboxDefaultSort = "FaxInboxDefaultSort",
  /** Dynamically loads fax inbox based on selected fax line. */
  FaxInboxLimitLoading = "FaxInboxLimitLoading",
  /** Enables OCR for the first few pages of incoming faxes */
  FaxInboxOCR = "FaxInboxOCR",
  /** Ability to search fax inbox by sender number and name */
  FaxSearchBySender = "FaxSearchBySender",
  /** Single flag for all new FO and Kareo enhancements */
  FrontOfficeNewKareo = "FrontOfficeNewKareo",
  /** Enables a UI reskin of the existing SleepOrder as a multipurpose order. */
  GenericOrder = "GenericOrder",
  /** Flag for whether appointment list should include appointemtns from all CDUs */
  GlobalApptList = "GlobalApptList",
  /** Flag for whether the context switcher should display notification from all CDUs */
  GlobalNotifications = "GlobalNotifications",
  /** Flag for whether patient search should return patients from all CDUs */
  GlobalPatientSearch = "GlobalPatientSearch",
  /** Two way sync with Google Calendar */
  GoogleCalendarSync = "GoogleCalendarSync",
  /** Increases the limit of fax pages that can be sent in a single fax */
  HigherFaxPagesLimit = "HigherFaxPagesLimit",
  /** A checkbox in the chart filer to extract history from IMS RTFs */
  IMSHistoryImport = "IMSHistoryImport",
  /** Indiana controlled substance print form */
  INControlledPrint = "INControlledPrint",
  /** Practices can eSubmit to Immunization Registries */
  IRSubmissions = "IRSubmissions",
  /** Immunization HL7 (CEHRT) */
  ImmunizationHl7 = "ImmunizationHl7",
  /** Determines whether a practice should use the CCDA smoking status import feature */
  ImportCcdaSmoking = "ImportCcdaSmoking",
  /** Determines whether a practice should use the CCDA vitals import feature */
  ImportCcdaVitals = "ImportCcdaVitals",
  /** Displays an in-app onboarding experience to providers who have not completed identity proofing and credential verification */
  InAppProviderOnboard = "InAppProviderOnboard",
  /** (In Development - Do Not Enable) - Displays an option in Elation where customers can learn about the Elation AI functionality and activate a trial. */
  InAppPurchElationAI = "InAppPurchElationAI",
  /** Show in app reports */
  InAppReports = "InAppReports",
  /** Improved tasking, billing, and ordering of in-house lab orders */
  InHouseLabWorkflow = "InHouseLabWorkflow",
  /** Enables coding automations when injections are recorded */
  InjCodingAutomation = "InjCodingAutomation",
  /** Enables the injection coding section in practice settings */
  InjCodingSettings = "InjCodingSettings",
  /** (In Development - Do Not Enable) Allows users to view a new form to document new and historical injections */
  InjectionsForm = "InjectionsForm",
  /** Adds support for a 'patient insurance card photo upload' question in patient forms */
  InsCardUploadPtForms = "InsCardUploadPtForms",
  /** (Beta) - Enables a practice to use ClaimMD as the RTE vendor. This flag has no effect if InsEligibilityCheck is not turned on. */
  InsEligClaimMD = "InsEligClaimMD",
  /** Insurance Eligibility Payer ID Field */
  InsEligPayerID = "InsEligPayerID",
  /** Determines whether insurance eligibility payer ID field in Insurance Settings is read only. Note: the Eligibility Payer ID field is only visible if InsEligPayerID is enabled. */
  InsEligPayerIDRdOnly = "InsEligPayerIDRdOnly",
  /** (In development) Enables real-time eligibility checking for patient insurances */
  InsEligibilityCheck = "InsEligibilityCheck",
  /** Allow for versioned patient insurances and to support the insurance domain migration to insurance-api */
  InsuranceVersioning = "InsuranceVersioning",
  /** (In development) Enables insurance policyholder relationship validations in the demographics dialog */
  InsuredValidations = "InsuredValidations",
  /** Enables an integrated partner transcription capability. Their chrome plug-in will only work if this is enabled. */
  IntegratedScribe = "IntegratedScribe",
  /** Enables the synching of Guarantor to and from Kareo. */
  KareoGuarantorSync = "KareoGuarantorSync",
  /** Disable strict XML parsing and try to recover on error when parsing Kareo SOAP */
  KareoXMLParseRecover = "KareoXMLParseRecover",
  /** Keep messages to self as 'Requiring action' */
  KeepReqActOnSelfMsgs = "KeepReqActOnSelfMsgs",
  /** Enables the coding automation section in the lab vendor details view */
  LabCodingAutomation = "LabCodingAutomation",
  /** Enables lab orders coding automation settings ("Lab Test Management" section) */
  LabCodingSettings = "LabCodingSettings",
  /** LabCorp eOrdering ** requires BiDiLabs ** */
  LabCorpEorders = "LabCorpEorders",
  /** Populate most recent pharmacy in the Rx Form if chart does not have preferred pharmacy saved. */
  LastPharmacyPref = "LastPharmacyPref",
  /** Reduces the fuzzy-matching of medication history download */
  LessFuzzyMedHx = "LessFuzzyMedHx",
  /** Secure Mail Feature enabled */
  Letters = "Letters",
  /** (In Development - Do Not Enable) - Display a summary of a practices purchased and used licenses on Manage User settings page */
  LicenseSummary = "LicenseSummary",
  /** Display Hubspot CTA on login */
  LoginCTA = "LoginCTA",
  /** Electronic ordering of controlled substances via MDToolbox */
  MDToolboxEPCS = "MDToolboxEPCS",
  /** Improves error handling for the MDToolbox EPCS flow */
  MDToolboxErrors = "MDToolboxErrors",
  /** MU Reports */
  MUReports = "MUReports",
  /** Mask the ssn field in patient Demographics */
  MaskSSN = "MaskSSN",
  /** Enabling an option for saving med order templates */
  MedOrderTemplates = "MedOrderTemplates",
  /** Some updates to medication history made during 2015 CEHRT */
  MedsHxCEHRTUpdates = "MedsHxCEHRTUpdates",
  /** Meds History Download */
  MedsHxDownload = "MedsHxDownload",
  /** Meds Hx Sign & Ignore Option */
  MedsHxIgnore = "MedsHxIgnore",
  /** Enables insurance company merge option from practice settings. This intends to reduce duplicated companies. */
  MergeInsCompany = "MergeInsCompany",
  /** Messaging to Groups Only */
  MessageGroupsOnly = "MessageGroupsOnly",
  /** Enable expanded workspace aware filtering */
  MoreWorkspaceFilters = "MoreWorkspaceFilters",
  /** Put most recent contacts at the top of the provider search autocomplete */
  MostRecentContacts = "MostRecentContacts",
  /** Default to no selected interface in the Doc Vax form when there are multiple live submission IR interfaces */
  MultiIRDefault = "MultiIRDefault",
  /** Flag for supporting multi-state identifiers primarily for e-prescribing */
  MultiStateERX = "MultiStateERX",
  /** (In Development - Do Not Enable) - Enables the latest Billing Home Improvements, such as compact view and column customization */
  NewBillingHome = "NewBillingHome",
  /** New coding automations being beta tested */
  NewCodingAutomation = "NewCodingAutomation",
  /** New contact dialog */
  NewContactEPN = "NewContactEPN",
  /** Enables the new reactified demographics dialog built out in 2021. */
  NewDemographics = "NewDemographics",
  /** Enables the new and improved office messages experience. */
  NewOfficeMessages = "NewOfficeMessages",
  /** Enables a reactified patient letter responder */
  NewPatientLetterUi = "NewPatientLetterUi",
  /** (Beta) Enables the new clinical form collection (questionnaire) summary format for display in Clinical Profile and exports to visit note */
  NewQuestionSummary = "NewQuestionSummary",
  /** Enabling creation of appointment slots in new scheduler */
  NewSchedulerApptSlot = "NewSchedulerApptSlot",
  /** Toggles CancelRx, RxChange, and RxFill functionality for Surescripts (pending certification) */
  NewSurescripts = "NewSurescripts",
  /** Uses 2FA only for MDToolbox EPCS Integration. Assumes MDToolboxEPCS, NewSurescripts, and SCRIPTv2017071 are turned on */
  NewSurescriptsEPCS = "NewSurescriptsEPCS",
  /** Default passport threads closed */
  NoPatientResponses = "NoPatientResponses",
  /** Enables outbound emails to be sent from a NoReply email to prevent DMARC failures. */
  NoReplyOutboundEmail = "NoReplyOutboundEmail",
  /** Do not share historical visit summaries with patient in passport */
  NoShareVisitSumm = "NoShareVisitSumm",
  /** Suppresses inclusion of failed bills/notes from the Urgent practice home queue */
  NoUrgentFailedBill = "NoUrgentFailedBill",
  /** No timer in visit note */
  NoVisitNoteTimer = "NoVisitNoteTimer",
  /** Note Assist product (Native AI Scribe) */
  NoteAssist = "NoteAssist",
  /** Allows a staff to use note assist, still requires them to have the NA flag. Requires special approval. */
  NoteAssistAllowStaff = "NoteAssistAllowStaff",
  /** Uses Deepgram to transcribe recordings instead of el8-transcribe/AWS transcribe */
  NoteAssistDeepgram = "NoteAssistDeepgram",
  /** Allows the user to edit the note via AI after it has been generated */
  NoteAssistEditor = "NoteAssistEditor",
  /** Enables practice/enterprise to enroll in Okta MFA */
  OktaMultifactor = "OktaMultifactor",
  /** Spotlight style search for prescriptions, lab orders, & visit notes */
  OmniBar = "OmniBar",
  /** Make insurance read-only for Kareo users */
  OneWayKareoInsur = "OneWayKareoInsur",
  /** (In development - DO NOT ENABLE) Holds submission of orders for specimen collection. */
  OrderHolding = "OrderHolding",
  /** Flag for supporting practices being able to share and receive external patient data via Carequality. */
  OutsideCare = "OutsideCare",
  /** Enables PMS vendors to create and update patient outstanding balance */
  OutstandingBalance = "OutstandingBalance",
  /** Access to PLR-specific searches and the compare profile dialog */
  PLR = "PLR",
  /** Access to PLR features for non-enterprise practices */
  PLR4Everyone = "PLR4Everyone",
  /** In patient chart, automatically show shared PLR documents upon load */
  PLRAutoShowSharedDoc = "PLRAutoShowSharedDoc",
  /** Ability to Sign & Import All for PLR */
  PLRMassImport = "PLRMassImport",
  /** Practices can check a PMP registry regarding patient prescriptions */
  PMPCheck = "PMPCheck",
  /** Hide practice contact-info in patient passport (for multi-site practices) */
  PassportHideContact = "PassportHideContact",
  /** This flag works alongside InHouseLabWorkflow. If enabled, the date of service for Staff Procedure Notes created via specimen collection will be the time of collection rather than today. */
  PastSpecimenCollDate = "PastSpecimenCollDate",
  /** Allows a Patient to Cancel an Appointment */
  PatientCanCancel = "PatientCanCancel",
  /** Enables the use of Patient Cohort functionality */
  PatientCohort = "PatientCohort",
  /** Enable State Filtering in patient list */
  PatientListByState = "PatientListByState",
  /** Patient Portal */
  PatientPortal = "PatientPortal",
  /** Enables setting the active/inactive/deceased status of a patient */
  PatientStatus = "PatientStatus",
  /** Display of payment site url on patient invoices */
  PaymentSiteInvoice = "PaymentSiteInvoice",
  /** Allows practice admins to view an audit log of changes to the permissions applied to a Physician or Staff user. */
  PermissionsAuditLog = "PermissionsAuditLog",
  /** (In development - Do Not Enable) Enables user to select a viewing time zone on the practice home calendar */
  PracticeCalendarTZ = "PracticeCalendarTZ",
  /** Allows the practice to access Membership Functionality */
  PracticeMemberships = "PracticeMemberships",
  /** Practice level feature flag for enabling Stripe Integration */
  PracticeStripeInt = "PracticeStripeInt",
  /** Certain fields auto-filled from latest PatientImmunization. Tracked at the med or vaccine level. */
  PracticeVaccinePrefs = "PracticeVaccinePrefs",
  /** Prenatal Flowsheets */
  PrenatalFlowsheets = "PrenatalFlowsheets",
  /** (Beta) Enables new PATCH/PUT APIs for Patient Problem edits in Clinical Profile to bypass previous immutable (soft-delete/recreate) behavior */
  ProblemUpdateAPI = "ProblemUpdateAPI",
  /** Providers network UI */
  ProvidersNetwork = "ProvidersNetwork",
  /** Enabling sorting by patient or provider activities in patient chart */
  PtChartChronoSort = "PtChartChronoSort",
  /** Access to coded chart data API for custom quality reporting. */
  QualityReportingAPI = "QualityReportingAPI",
  /** Whether or not the practice is RCM enabled or not. */
  RCMEnabledPractice = "RCMEnabledPractice",
  /** Enables the reactified Add/Edit Patient Allergy Dialog */
  ReactAllergiesDialog = "ReactAllergiesDialog",
  /** Enables the new reactified Patient Passport Messages page in practice settings. For development only. Do not enable. */
  ReactPtPassportMsgs = "ReactPtPassportMsgs",
  /** Remove default imaging vendors from practices */
  RemoveDefaultImaging = "RemoveDefaultImaging",
  /** Report Notes at Bottom */
  ReportNotesAtBottom = "ReportNotesAtBottom",
  /** Allow providers to delegate reports to staff and groups. Must go with FaxDelegateModal. This will be a practice setting eventually. */
  ReportQueueDelegate = "ReportQueueDelegate",
  /** When enabled this disables the option to select All Physicians when running the appointment report. Users will need to select a specific physician when running the report */
  ReqPhyscnOnApptRprt = "ReqPhyscnOnApptRprt",
  /** Allows users to view and restore deleted faxes in a fax inbox. */
  RestoreDeletedFaxes = "RestoreDeletedFaxes",
  /** Use the Ribbon database instead of the CMS database copy for EPN. */
  RibbonEpn = "RibbonEpn",
  /** Use the new SCRIPT standard when handling eRx */
  SCRIPTv2017071 = "SCRIPTv2017071",
  /** Practice home queue routing optimized for enterprise learnings */
  ScaledQueueRouting = "ScaledQueueRouting",
  /** Enables time zone to be configured on the booking site */
  SchedulingTimeZone = "SchedulingTimeZone",
  /** Default to service location from last bill from the same physician */
  ServLocFromLastBill = "ServLocFromLastBill",
  /** Flag for the udpated navigatino bar on settings. */
  SettingsNavBar = "SettingsNavBar",
  /** Flag to support creating staff-group to practice delegates */
  StaffGroupDelegates = "StaffGroupDelegates",
  /** Flag for supporting smarter picking of state when determining the controlled substance schedule of a medication */
  StateEPCS = "StateEPCS",
  /** Allows the practice to use Stripe Identity for identity proofing users */
  StripeIdp = "StripeIdp",
  /** During case creation will attempt to answer users' issue with excerpts from Elation Help Documentation */
  SupportAIEHRAnswers = "SupportAIEHRAnswers",
  /** (In Development - Do Not Enable) - Enables a new support ticket creation experience that simplifies the methods for contacting Support, leverages AI to categorize cases, and requests specific data inputs based on the type of case being created */
  SupportIntakeWithAI = "SupportIntakeWithAI",
  /** (In development - DO NOT ENABLE) For Elation Billing customers, syncs Billing Alerts from EB in the New Appointment (Create an Event) dialog. One-way sync only. */
  SyncBillingAlerts = "SyncBillingAlerts",
  /** Replaces office message system with task management system */
  TaskManagement = "TaskManagement",
  /** Streamlined Rx Discontinue workflow */
  UnifiedDiscontinue = "UnifiedDiscontinue",
  /** Use an external CQM calculator such as FIGmd */
  UseExternalCQMCalc = "UseExternalCQMCalc",
  /** Give colleagues a discounted invitation */
  UserReferral = "UserReferral",
  /** Push surveys to users on practice home after-hours */
  UserSurveys = "UserSurveys",
  /** VIP Access Control */
  VIPAccessControl = "VIPAccessControl",
  /** Enables coding automations when vaccines are recorded */
  VaxCodingAutomation = "VaxCodingAutomation",
  /** Enables the vaccine coding section in practice settings */
  VaxCodingSettings = "VaxCodingSettings",
  /** Enables the new rich text editor-based Visit Note 2.0 */
  VisitNote2 = "VisitNote2",
  /** Disables the ability to create new Visit Note 2.0's while allowing updates and deletions of existing ones. */
  VisitNote2CreateOff = "VisitNote2CreateOff",
  /** Enables the capturing of structured data in Visit Note 2.0 via Custom Blocks */
  VisitNote2StructData = "VisitNote2StructData",
  /** Enables the usage of Note 2.0 templates */
  VisitNote2Templates = "VisitNote2Templates",
  /** Enable workspace filtering of the requiring actions queues */
  WorkspaceAwareRAQ = "WorkspaceAwareRAQ",
  /** Adds workspace feature to the practice */
  Workspaces = "Workspaces",
  /** POC: Flag for supporting practices being able to access external patient data via Zus tools */
  ZusOutsideCare = "ZusOutsideCare"
};

export default Feature;
