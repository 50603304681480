import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { QueryClientProvider } from '@tanstack/react-query';

import { SentryErrorBoundary } from 'utils/sentry';
import { AppQueryClient } from 'utils/react-query';
import { ReduxQueryProvider } from 'utils/redux-query';

const createRenderFn = (Component, getStoreFn, props) => {
  let HotComponent = hot(Component);

  return (id, propsFromRenderCaller = {}) => {
    const element = document.getElementById(id);

    // Save the legacy passed ref in this closure and pass it more directly
    //  because hot() will mess with any refs
    const { ref, ...renderCallerProps } = propsFromRenderCaller;

    if (ref) {
      HotComponent = hot(
        React.forwardRef((fwdProps, _ref) => {
          return React.createElement(Component, { ...fwdProps, ref });
        }),
      );
    }

    if (element) {
      ReactDOM.render(
        // eslint-disable-next-line react/jsx-filename-extension
        <SentryErrorBoundary>
          <QueryClientProvider client={AppQueryClient}>
            <Provider store={getStoreFn()}>
              <ReduxQueryProvider>
                <HotComponent {...props} {...renderCallerProps} />
              </ReduxQueryProvider>
            </Provider>
          </QueryClientProvider>
        </SentryErrorBoundary>,
        element,
      );
    } else {
      console.error(`Unable to find an element with the id ${id}`);
    }
  };
};

export default createRenderFn;
